import React, { useState, useEffect } from "react";

import { formatRelative } from "date-fns";
// import { Button, LuxStylesProvider } from '@verint/shared-react-components';
import {
  VirtualTable,
  Label,
  Popup,
  Button,
  LuxStylesProvider,
  ProgressSpinner,
} from "@verint/shared-react-components";
import "@verint/lux_icon_font/verint_lux.css";
import GivenTime from "./GivenTime";
import DesktopNumber from "./DesktopNumber";
import TeardownDate from "./TeardownDate";
import LabSchedule from "./LabSchedule";
import AdditionalVcorpUsers from "../../Components/AdditionalVcorpUsers.jsx";
import TandCCheckbox from "../../Components/T&CCheckbox";
import $, { event } from "jquery";
import PodNumber from "../../Components/PodNumber";
import MobileGatewayCheckbox from "../../Components/MobileGatewayCheckbox";
import RequestAnythingCheckbox from "../../Components/RequestAnything.jsx";
import LiveRecordingCheckbox from "../../Components/LiveRecordingCheckbox";
import LMSCheckbox from "../../Components/LMSCheckbox";
import VTACheckbox from "../../Components/VTACheckbox";
import MSTeamsAzureCheckbox from "../../Components/MSTeamsAzureCheckbox";
import OperationManagerCheckbox from "../../Components/OMWFECheckbox";
import FlexManagerCheckbox from "../../Components/FlexManagerCheckBox";
import FriendlyURL from "../../Components/FriendlyURL";
import EmployeeTimecollector from "../../Components/EmployeeTimeCollector";
import ScheduleHistoryCheckbox from "../../Components/ScheduleHistory";
import UrlName from "../../Components/UrlName";
import S3BuketCheckbox from "../../Components/S3BucketCheckBox";
import DaVinciCheckBox from "../../Components/DaVinciCheckBox";
import AWSLiveRecordingCheckbox from "../../Components/AwsLiveRecording";
import CloudWFODemoCheckbox from "../../Components/CloudWFODemoCheckbox";
import CommunityCheckbox from "../../Components/CommunityCheckbox";
import EmailAddress from "../../Components/EmailAddress";
import ForwardEmail from "../../Components/ForwardEmail";
import Region from "../../Components/Region";
import LuxCheckbox from "../../Components/LuxUl";
import IvaProCheckbox from "../../Components/IvaProCheckbox";
import TimeFlex from "../../Components/Timeflex.jsx";
import CallSummaryCheckBox from "../../Components/CallSummaryCheckBox.jsx";
import DaVinciAPICredentialsCheckBox from "../../Components/DaVinciAPICredentialsCheckBox.jsx";
import IntelligentForecastingCheckBox from "../../Components/IntelligentForecastingCheckBox.jsx";
import ALBCheckbox from "../../Components/ALBCheckbox.jsx";
import DesktopRequired from "../../Components/DesktopRequired";
import CloudIngestionRecorder from "../../Components/CloudIngestionRecorder.jsx";
import "./currentRequest.css";
// import GetServerCredentials from './GetServerCredentials';
// import ResendEmail from './ResendEmail';

let provisionRecords = [],
  provisionProcessingRecords = [],
  teardownRecords = [],
  tableRows = [],
  columnNames = [];

function edit_records() {
  return (
    <>
      <div>Edit</div>
    </>
  );
}

function CurrentRequestHome() {
  // Assigning null array to provisionData, provisionProcessingData, teardownData
  // which can be updated with the data after fetch using setProvisionData, setProvisionProcessingData, setTeardownData methods
  const [provisionData, setProvisionData] = useState([]);
  const [provisionProcessingData, setProvisionProcessingData] = useState([]);
  const [teardown_records, setTeardown_records] = useState([]);
  const [superAdmins, setsuperAdmins] = useState([]);
  const [userEmail, setUserEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [token, setToken] = useState("");
  const [config, setConfig] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [group, setGroup] = useState(null);
  const [RequestformDataDB, setRequestformDataDB] = useState(null);
  const [displayTeardownDate, setDisplayTeardownDate] = useState(false);
  const [displayGivenTime, setDisplayGivenTime] = useState(false);
  const [displayDesktopNumber, setDisplayDesktopNumber] = useState(" ");
  const [displayDisableSchedule, setDisplayDisableSchedule] = useState(" ");
  const [editform, setEditForm] = useState(null);
  const [loading, setLoading] = useState(false);

  const [viewLabDetails, setviewLabDetails] = useState(null);

  const open = Boolean(anchorEl);
  const openlabDetails = Boolean(viewLabDetails);
  let RequestManagedata = [];
  let is_superAdmin = false;

  function get_token() {
    console.log("INFO | GetToken function");
    var url = window.location.href;
    var tokensString = url.split("#")[1];
    var startOfIdToken = tokensString.indexOf("=") + 1;
    var endOfIdToken = tokensString.indexOf("&");
    var token = tokensString.substring(startOfIdToken, endOfIdToken);
    // console.log("INFO | token", token)
    return token;
  }

  function get_super_admins(Requestformdata) {
    superAdmins_json = [];
    for (var i = 0; i < Requestformdata.Count; i++) {
      if (Requestformdata.Items[i].template_name == "admin_emails_config") {
        var superAdmins =
          Requestformdata.Items[i].currentRequests_disableSchedule_superAdmins;
        var superAdmins_json = JSON.stringify(superAdmins);
        break;
      }
    }
    return superAdmins_json;
  }

  function get_templateId(Requestformdata, Template_name) {
    for (var i = 0; i < Requestformdata.Count; i++) {
      if (Requestformdata.Items[i].template_name == Template_name) {
        var TemplateId_value = Requestformdata.Items[i].TemplateId;
        var TemplateId = JSON.stringify(TemplateId_value);
      }
    }
    return TemplateId;
  }

  function ParseToken(configData) {
    // Temporary redirect for testing producition before promotion of V2
    //var authURL = "https://auth.verint.training/login?response_type=token&client_id=21o2ha538vus324p23sa0t5d3m&redirect_uri=http://localhost:3000/template-admin";
    //Development Redirect
    //console.log("REACT_APP_AUTH_URL :",configData.REACT_APP_AUTH_URL)
    //console.log("auth NODE_ENV :",process.env.NODE_ENV)
    //console.log("auth url :",configData.REACT_APP_AUTH_URL)
    var authURL = configData.REACT_APP_AUTH_URL;
    var url = window.location.href;

    var tokensString = url.split("#")[1];

    // If a token string was not found redirct to cognito login
    if (tokensString === undefined) {
      window.location.href = authURL;
    }

    var startOfIdToken = tokensString.indexOf("=") + 1;
    var endOfIdToken = tokensString.indexOf("&");
    var token = tokensString.substring(startOfIdToken, endOfIdToken);

    //console.log("INFO || The token value is", token)

    setToken(token);
    //document.getElementById('token').value = token; // set token in hidden field
    // If the token variable is empty redirct to cognito login
    if (token === "") {
      window.location.href = authURL;
    }

    // Spliting the token into its section
    var sections = token.split(".");
    var tokenPayload = atob(sections[1]);

    // Check if the token is expired, if so return the user to cognito login
    var current_ts = Math.floor(new Date() / 1000);
    try {
      console.log(
        "Token time exp: " +
          JSON.parse(tokenPayload).exp +
          "\nCurrent Time: " +
          current_ts
      );
      if (current_ts > JSON.parse(tokenPayload).exp) {
        window.location.href = authURL;
      }
    } catch (e) {
      window.location.href = authURL;
    }

    // Parse tokenPayload in json object, if error redirect to cognito login
    try {
      var jsonPayload = JSON.parse(tokenPayload);
    } catch (e) {
      window.location.href = authURL;
    }
    // This will change the dropdown box options based on the cognito groups that the user is apart of
    return jsonPayload;
  }

  // grab the records and store it in a variable
  const fetchRows = async () => {
    var tokenJson = ParseToken(config);
    var userEmail = tokenJson["cognito:username"];
    var token = get_token();
    var command = "fetch_teardown";
    console.log("INFO | User email: ", userEmail);
    var group = tokenJson["cognito:groups"][0];
    setGroup(group);
    setIsLoading(true);
    setUserEmail(userEmail);
    setToken(token);
    //console.log("INFO | Token value:  ", token);

    const response = await fetch(
      "/fetchRecords?username=" + userEmail + "&token=" + token
    );
    const jsonData = await response.json();

    console.log("INFO | JSON response: ", jsonData);

    provisionRecords = jsonData["ProvisionRecords"];
    provisionProcessingRecords = jsonData["ProvisionProcessingRecords"];
    teardownRecords = jsonData["TeardownRecords"];

    // Fetch RequestFormData

    //console.log("fetchDBRecord token:",token)
    const responseDB = await fetch("/template/items?token=" + token);
    const DbRecord = await responseDB.json();
    RequestManagedata = DbRecord;
    setRequestformDataDB(RequestManagedata);
    //console.log("INFO | The DB record from RequestFormManagement Table is ::::",RequestManagedata)

    setProvisionData(provisionRecords);
    setProvisionProcessingData(provisionProcessingRecords);
    //var requestFormData = fetchDBRecord();
    var superadmins = get_super_admins(RequestManagedata);
    setsuperAdmins(superadmins);
    console.log("superadmin", superAdmins);
    if (superadmins.includes(userEmail.toLowerCase())) {
      is_superAdmin = true;
      console.log("is_superAdmin: ", is_superAdmin);
    }
    setIsLoading(false);
  };

  const get_react_config = async () => {
    const response = await fetch(`/config`);
    const jsonData = await response.json();
    setConfig(jsonData);

    ParseToken(jsonData);
    //console.log("Config Json Data :",jsonData)
  };

  function add_status_column(arrayObj, status_value) {
    try {
      if (arrayObj.length > 0) {
        // Find the index of an object with a specific property value
        for (var i = 0; i < arrayObj.length; i++) {
          if (!arrayObj[i].hasOwnProperty("Status")) {
            //TO DO : Check if FormatTeardownDate is past date then set status as Failed Deployment
            arrayObj[i].Status = status_value;
          }
        }
      }
    } catch (e) {
      console.log("Error while adding status to array");
    }
    return arrayObj;
  }

  useEffect(
    () => {
      //Hide scrolll bar
      $("html, body").css("overflowY", "hidden");
      get_react_config();
      if (config != undefined) {
        fetchRows();
      }
    },
    [token],
    [config]
  );

  let provisionData_with_status = add_status_column(
    provisionData,
    "Future Request"
  );
  let provisionProcessingRecords_with_status = add_status_column(
    provisionProcessingRecords,
    "Provisioning"
  );
  let teardownRecords_with_status = add_status_column(
    teardownRecords,
    "Deployed"
  );
  let tableRows = [
    ...provisionData_with_status,
    ...provisionProcessingRecords_with_status,
    ...teardownRecords_with_status,
  ];

  let columnNames = [
    {
      capitalized: true,
      displayName: "Record Id",
      key: "id",
      sortable: false,
      width: 0,
    },
    {
      capitalized: true,
      displayName: "Requestor",
      key: "Requestor",
      sortable: true,
      width: 1000,
    },
    {
      displayName: "ServerName",
      key: "ServerName",
      sortable: true,
      width: 1200,
    },
    {
      displayName: "Environment",
      key: "EnvironmentName",
      sortable: true,
      width: 1100,
    },
    // {
    //   displayName: 'Lab Details',
    //   key: 'ServerName',
    //   width: 500,
    //   editable: false,
    //   editableRender: React.forwardRef(
    //     ({ defaultValue, row, rest, errors, onChange }, ref) => (
    //       <GetServerCredentials email={row.email}/>
    //     )
    //   )
    // },
    // {
    //   displayName: 'Region',
    //   key: 'Region',
    //   width: 1000
    // },
    {
      displayName: "Start date",
      key: "ProvisionDate",
      sortable: true,
      width: 1000,
    },
    {
      displayName: "End Time(UTC)",
      key: "TeardownDate",
      sortable: true,
      width: 1000,
      editable: true,
      editableRender: React.forwardRef(
        ({ defaultValue, row, rest, errors, onChange }, ref) => (
          <TeardownDate
            id={"teardownDateValue" + row.id}
            teardownDate={row.FormatTeardownDate}
            displayTeardownDate={displayTeardownDate}
            setDisplayTeardownDate={setDisplayTeardownDate}
          ></TeardownDate>
        )
      ),
    },
    {
      displayName: "Extended Count",
      key: "LabExtendedCount",
      sortable: false,
      width: 800,
    },
    {
      displayName: "Given Time",
      key: "GivenTime",
      sortable: false,
      width: 600,
      editable: true,
      editableRender: React.forwardRef(
        ({ defaultValue, row, rest, errors, onChange }, ref) => (
          <GivenTime
            id={"givenTimeValue" + row.id}
            givenTime={row.GivenTime}
            displayGivenTime={displayGivenTime}
            setDisplayGivenTime={setDisplayGivenTime}
          />
        )
      ),
    },
    {
      displayName: "Schedule",
      key: "Timezone",
      width: 1000,
      sortable: true,
      // editable: true,
      // editableRender: React.forwardRef(
      //   ({ defaultValue, row, rest, errors, onChange }, ref) => (
      //   //  <Button data-testid="verint-button" onClick={(event) => {setAnchorEl(event.currentTarget); }}>edit Request</Button>
      //    <DisableSchedule id={"disableScheduleValue"+row.id} scheduleStatus={row.DisableSchedule} displayDisableSchedule={displayDisableSchedule} setDisplayDisableSchedule={setDisplayDisableSchedule} index={row.id} token={token} ServerName={row.ServerName} RecordId={row.id} is_superAdmin={superAdmins.includes(userEmail.toLowerCase())?true:false } ></DisableSchedule>
      //    )
      // )
    },
    {
      displayName: "Desktops",
      key: "DesktopsRequired",
      sortable: false,
      width: 500,
      editable: true,
      editableRender: React.forwardRef(
        ({ defaultValue, row, rest, errors, onChange }, ref) => (
          <DesktopNumber
            id={"numberOfDesktopValue" + row.id}
            numberOfDesktops={row.DesktopsRequired}
            displayDesktopNumber={displayDesktopNumber}
            setDisplayDesktopNumber={setDisplayDesktopNumber}
          />
        )
      ),
    },
    {
      displayName: "Last Launch",
      key: "LastLaunch",
      sortable: true,
      width: 700,
    },
    {
      displayName: "Tracking Id",
      key: "TrackingId",
      sortable: true,
      width: 1500,
    },
    {
      displayName: "Status",
      key: "Status",
      sortable: true,
      width: 800,
    },
    {
      capitalized: true,
      displayName: "AdditionalVcorpUsers",
      key: "AdditionalVcorpUsers",
      sortable: false,
      width: 0,
    },
  ];
  const handleViewLabDetailsEvent = (event) => {
    console.log("handleViewLabDetailsEvent Event occurred:" + event);
    //alert("View Lab Details ")
    setviewLabDetails(event);
  };

  const handleEditEvent = async (event) => {
    setLoading(true);
    let teardown_data = [];
    console.log("handleEditEvent Event occurred:", event);
    // Checking the Status
    if (event.Status == "Deployed") {
      const command = "editform_data";
      //Request function to fetch data for Edit Form
      const options = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: `{"token":"${token}", "command": "${command}", "recordId":"${event.id}", "username" : "${userEmail}"}`,
      };
      // Constructing edit form request object
      const request = new Request("/fetchRecords", options);
      try {
        const response = await fetch(request);
        console.log("response", response);
        if (response.status === 200) {
          teardown_data = await response.json();
          // teardown_data = await response.json();
          console.log("teardown_data", teardown_data); // This should now log the actual data
        } else {
          console.error("Error: ", response.statusText);
        }
      } catch (error) {
        console.error("Error:", error);
      }
      setEditForm(teardown_data[0]);
    }

    setAnchorEl(event);
    setLoading(false);
  };

  const handleSendMailEvent = (event) => {
    console.log("handleSendMailEvent Email Event occurred:" + event);
    //alert("email clicked ")
    // alert("send mail  clicked",this);
    //{event ==null?"Empty":<ResendEmail id={"resendEmailValue"+event.id} index={event.id} token={token} ServerName={event.ServerName} EmailBody={event.email} AdditionalUserEmail={event.EmailAddress} ReceiverEmailid={event.CognitoEmailAddress} TrackingId={event.TrackingId}/>}
    var ServerName = event.ServerName;
    var email_body = event.email;
    var emailBody = email_body.replace(/("|“|”)/g, '\\"');
    var reciever_email = [
      event.CognitoEmailAddress,
      event.EmailAddress,
      "vulabs@verint.com",
    ];
    var serverPrefix = ServerName.split(".");

    // Add confirmation window to make sure you are resending mail of right server
    var option = window.confirm(
      `Are you sure, you want to resend an email of ${ServerName} lab?`
    );
    if (option) {
      // Make request to server with all of our values
      var options = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: `{"token":"${token}", "command":"MSteams", "reciever_email": "${reciever_email}", "email_body": "${emailBody}", "subject": "Verint Lab Request: ${serverPrefix[0]} (${event.TrackingId})"}`,
      };
      // Constructing request object
      const request = new Request("/resendEmail", options);

      window.alert(`Email sent!!`);

      // Sending request
      fetch(request).then((response) => {
        if (response.status === 200) {
          console.log("connection successful");
        } else {
          window.alert("ERROR | Error with connection.");
        }
      });
    }
  };

  const handleDeleteEvent = (event) => {
    console.log("handleDeleteEvent Event occurred:" + event);
    var ServerName = event.ServerName;

    // Add confirmation window to make sure you are deleting the proper lab
    var option = window.confirm(
      `Are you sure, you want to teardown ${ServerName} lab?`
    );
    if (option) {
      // Enter 'Delete' to delete the lab on comfirmation
      var input = prompt("To confirm deletion, please type DELETE :");
      // Accept any version of the word 'Delete' and convert it into lowercase
      var convertedInput = input.toLowerCase();
      //console.log("event.id: ", event.id)
      // Get value of single parameter
      var logged_user = userEmail.split("@");
      logged_user = logged_user[0];
      // Output value to console
      //console.log(logged_user);

      // Delete the lab by making the request only if the prompt input is 'Delete'
      if (convertedInput === "delete") {
        // Make request to server with all of our values
        var options = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: `{"token" : "${token}", "RecordId" : "${event.id}", "LoggedUser" : "${logged_user}"}`,
        };
        //console.log(options);
        // Constructing request object
        const request = new Request("/terminated", options);

        // Local Testing
        //const request = new Request("http://localhost:3000/terminated",options);

        alert(
          " Your request has been updated! It takes 2-5mins for lab deletion."
        );
        // Sending request
        fetch(request).then((response) => {
          if (response.status === 200) {
            console.log("INFO | Lab Was Successfully Terminated!");
            window.location.reload(); // Refreshing and updating page
          } else {
            console.log("ERROR | Unable to terminate lab.");
          }
        });
      }
      // If prompt input is other than 'Delete', alert with invalid input
      else {
        alert("Incorrect input");
      }
    }
  };

  const handleSaveButton = (event) => {
    console.log("handleSaveButton Event RecordId: ", event.RecordId);
    //console.log('FormatTeardownDate:' + event.FormatTeardownDate);

    var lab_schedule_current_element =
      document.getElementById("ScheduleNameValue");
    var lab_schedule_current_value =
      lab_schedule_current_element != null
        ? lab_schedule_current_element.value
        : event.Timezone;
    var form_teardown_date_element =
      document.getElementsByName("teardownDateValue");
    var form_teardown_date =
      form_teardown_date_element != null
        ? form_teardown_date_element[0].value
        : event.FormatTeardownDate;
    var desktopNumber_element = document.getElementById("desktopNumverValue");
    var desktopNumber_value =
      desktopNumber_element != null
        ? desktopNumber_element.value
        : event.DesktopsRequired;
    var givenTime_element = document.getElementById("givenTimeValue");
    var givenTime_value =
      givenTime_element != null ? givenTime_element.value : event.GivenTime;
    var emailAddress = document.getElementById("EmailAddress");
    var emailAddress_value =
      emailAddress != null ? emailAddress.value : event.EmailAddress;
    console.log("emailAddress_value", emailAddress_value);

    //console.log("lab_schedule_current_value",lab_schedule_current_value)
    // console.log("form_teardown_date",form_teardown_date)
    // console.log("desktopNumber_value",desktopNumber_value)
    // console.log("givenTime_value",givenTime_value)
    // if (event.FormatTeardownDate != form_teardown_date){
    //   alert("Teardown Date Changed: ", event.FormatTeardownDate)
    // }
    // else{
    //   alert("Teardown Not Changed ")
    // }
    setAnchorEl(null);
    //Add confirmation window to make sure you are extending the lab to desired time

    //console.log("Event: ", event.id)
    var option = window.confirm(
      `Are you sure, you want to update the values for: ${event.ServerName} lab?`
    );
    if (option) {
      // Make request to server with all of our values
      var options = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: `{"token" : "${token}", "RecordId" : "${event.id}", "updatedTeardownDate" : "${form_teardown_date}", "updatedGivenTime" : "${givenTime_value}", "updatedDesktopNumber" : "${desktopNumber_value}", "updatedScheduleValue" : "${lab_schedule_current_value}", "updatedEmailAddress" : "${emailAddress_value}"}`,
      };
      const request = new Request("/saveFormData", options);

      window.alert(`Your request has been acknowledged.`);

      // Sending request
      fetch(request).then((response) => {
        if (response.status === 200) {
          console.log("connection successful");
          window.location.reload(); // Refreshing and updating page
        } else {
          window.alert("ERROR | Error with connection.");
        }
      });
    }
  };
  // const handleRowRenderEvent = event => {
  //   console.log('handleRowRenderEvent Event occurred:' + event);
  //   alert("handleRowRenderEvent clicked ")
  //    //setAnchorEl(event)
  // };
  let customActionTitle = "Actions";
  let customActions = [
    {
      icon: "icon-Cards-View",
      name: "View Lab Details",
      onClick: handleViewLabDetailsEvent,
    },
    {
      icon: "icon-edit",
      name: "Edit",
      onClick: handleEditEvent,
    },
    {
      icon: "icon-mail",
      name: "Re-send Mail",
      onClick: handleSendMailEvent,
    },
    {
      icon: "icon-trash",
      name: "Delete Lab",
      // onClick: function edit_records(){
      //   alert(" delete clicked",this);
      //   //Call Delete function
      // }
      onClick: handleDeleteEvent,
    },
  ];

  //let cellRenderers= ""
  let cellRenderers = [
    {
      ProvisionDate: (value, row) =>
        value ? formatRelative(new Date(value), new Date()) : null,
      Environment: (value) => "test",
    },
  ];

  return (
    <>
      {loading && (
        // <div style={{ display: "flex", justifyContent: "center" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            position: "fixed",
            top: "40%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 1000,
          }}
        >
          <ProgressSpinner size="3rem" data-testid="verint-progress-spinner" />
        </div>
      )}

      <div className={`luxTable ${loading ? "blur-background" : ""}`}>
        {/* // <div className="luxTable"> */}
        <br />
        <Popup
          CloseIconButtonProps={{ "aria-label": "close" }}
          HelpIconButtonProps={{ "aria-label": "help" }}
          alertText="Lab Details Email"
          checkboxText="Are you sure?"
          handleConfirm={() => {
            handleViewLabDetailsEvent(null);
          }}
          data-testid="verint-popup"
          handleClose={() => {
            handleViewLabDetailsEvent(null);
          }}
          rejectBtn
          rejectText="Cancel"
          size="large"
          title="Lab Request"
          type="info"
          anchorEl={viewLabDetails}
          open={openlabDetails}
        >
          <div style={{ padding: "20px" }}>
            <div
              dangerouslySetInnerHTML={{
                __html: viewLabDetails == null ? "EMPTY" : viewLabDetails.email,
              }}
            />
          </div>
        </Popup>
        {/* {loading && (
        // <div style={{ display: "flex", justifyContent: "center" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            position: "fixed",
            top: "10%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 1000,
          }}
        >
          <ProgressSpinner size="3rem" data-testid="verint-progress-spinner" />
        </div>
      )} */}

        {/* <Button data-testid="verint-button" onClick={(event) => {setAnchorEl(event.currentTarget); }}>edit Request</Button> */}
        <Popup
          CloseIconButtonProps={{ "aria-label": "close" }}
          HelpIconButtonProps={{ "aria-label": "help" }}
          alertText="Alert Text"
          checkboxText="Are you sure?"
          confirmBtn
          confirmText="SAVE"
          handleConfirm={() => {
            handleSaveButton(anchorEl);
          }}
          data-testid="verint-popup"
          handleClose={() => {
            setAnchorEl(null);
            setEditForm(null);
          }}
          hasActionsDivider
          rejectBtn
          rejectText="Cancel"
          size="large"
          title="Edit Lab Request "
          type="basic"
          anchorEl={anchorEl}
          editform={editform}
          open={open}
        >
          {/* <form id="requestform" method="POST" data-toggle="validator" encType="application/x-www-form-urlencoded" action={"/saveEditForm"} ref={formRef} handleConfirm={handleSubmit(onSubmit)}

          autoComplete="off" style={{ position: 'relative', margin: '0px 0px 24px', }}> */}

          <table style={{ border: "hidden" }} className="editForm_table">
            <tr
              className="editForm_table-row"
              style={{ border: "hidden", display: "none" }}
            >
              <td style={{ border: "hidden" }}>Record Id</td>

              <td style={{ border: "hidden" }}>
                {anchorEl == null ? "Empty" : anchorEl.id}
              </td>
            </tr>

            <tr className="editForm_table-row" style={{ border: "hidden" }}>
              <td className="td_key" align="left">
                Requestor
              </td>

              <td className="td_value" align="left">
                {anchorEl == null ? "Empty" : anchorEl.CognitoEmailAddress}
              </td>
            </tr>

            <tr className="editForm_table-row" style={{ border: "hidden" }}>
              <td className="td_key">Environment</td>

              <td className="td_value" align="left">
                {anchorEl == null ? "Empty" : anchorEl.ServerName}
              </td>
            </tr>

            {anchorEl == null ? (
              "Empty"
            ) : (
              <LabSchedule
                id={"LabSchedule" + anchorEl.id}
                group={group}
                item={anchorEl}
                LabScheduleValue={anchorEl != null ? anchorEl.Timezone : ""}
              ></LabSchedule>
            )}

            {anchorEl == null ? (
              "Empty"
            ) : (
              <DesktopNumber
                id={"numberOfDesktopValue" + anchorEl.id}
                item={anchorEl}
                numberOfDesktops={anchorEl.DesktopsRequired}
                displayDesktopNumber={displayDesktopNumber}
                setDisplayDesktopNumber={setDisplayDesktopNumber}
              ></DesktopNumber>
            )}

            {anchorEl == null ? (
              "Empty"
            ) : (
              <TeardownDate
                item={anchorEl}
                id={"teardownDateValue" + anchorEl.id}
                teardownDate={anchorEl.TeardownDate}
                displayTeardownDate={displayTeardownDate}
                setDisplayTeardownDate={setDisplayTeardownDate}
                superAdmins={superAdmins}
              ></TeardownDate>
            )}

            {anchorEl == null ? (
              "Empty"
            ) : (
              <GivenTime
                item={anchorEl}
                id={"givenTimeValue" + anchorEl.id}
                givenTime={anchorEl.GivenTime}
                displayGivenTime={displayGivenTime}
                setDisplayGivenTime={setDisplayGivenTime}
                index={anchorEl.id}
                token={token}
              ></GivenTime>
            )}

            <tr className="editForm_table-row" style={{ border: "hidden" }}>
              <td className="td_value td_value_left" align="left">
                {anchorEl == null ? (
                  "Empty"
                ) : editform == null ? null : editform.MobileGateway ==
                  "true" ? (
                  <MobileGatewayCheckbox
                    group={group}
                    environemntRecords={RequestformDataDB}
                    environmentSelected={editform.TemplateId}
                    editForm={editform.MobileGateway}
                  />
                ) : null}
              </td>
            </tr>
            {anchorEl == null ? (
              "Empty"
            ) : editform == null ? null : parseInt(editform.DesktopsRequired) >
              0 ? (
              // <DesktopNumber id={"numberOfDesktopValue"+anchorEl.id} numberOfDesktops={anchorEl.DesktopsRequired} displayDesktopNumber={displayDesktopNumber} setDisplayDesktopNumber={setDisplayDesktopNumber}  /> : null)
              <DesktopNumber
                environmentSelected={anchorEl.TemplateId}
                environemntRecords={RequestformDataDB}
                group={group}
                editForm={parseInt(editform.DesktopsRequired)}
                disabled={editform.DesktopsRequired}
                checked={editform.DesktopsRequired}
              />
            ) : null}

            {anchorEl == null ? (
              "Empty"
            ) : editform == null ? null : parseInt(editform.NumberOfUsers) >
              0 ? (
              <AdditionalVcorpUsers
                environmentSelected={anchorEl.TemplateId}
                environemntRecords={RequestformDataDB}
                group={group}
                editForm={editform.NumberOfUsers}
                NumberOfUsers={editform.NumberOfUsers}
                disabled={editform.NumberOfUsers}
                checked={editform.NumberOfUsers}
              />
            ) : null}

            {anchorEl == null ? (
              "Empty"
            ) : editform == null ? null : editform.FriendlyURL == "true" ? (
              <FriendlyURL
                environmentSelected={editform.TemplateId}
                group={group}
                environemntRecords={RequestformDataDB}
                disabled={editform.FriendlyURL}
                checked={editform.FriendlyURL}
              />
            ) : null}

            <tr className="editForm_table-row" style={{ border: "hidden" }}>
              {anchorEl == null ? (
                "Empty"
              ) : editform == null ? null : editform.Additional_recipient ==
                "true" ? (
                <ForwardEmail
                  group={group}
                  environemntRecords={RequestformDataDB}
                  environmentSelected={editform.TemplateId}
                  disabled={editform.Additional_recipient}
                  checked={editform.Additional_recipient}
                />
              ) : null}

              <td className="td_value_right">
                {anchorEl == null ? (
                  "Empty"
                ) : editform == null ? null : editform.Additional_recipient ==
                  "true" ? (
                  <EmailAddress
                    id={"EmailAddress"}
                    group={group}
                    environemntRecords={RequestformDataDB}
                    environmentSelected={editform.TemplateId}
                    editForm={editform.Additional_recipient}
                    EmailAddress={editform.EmailAddress}
                  />
                ) : null}
              </td>
            </tr>

            {anchorEl == null ? (
              "Empty"
            ) : editform == null ? null : editform.TandC == "true" ? (
              <TandCCheckbox
                group={group}
                environemntRecords={RequestformDataDB}
                environmentSelected={editform.TemplateId}
                TandCSelected={editform.TandC}
                setTandCSelected={editform.TandC}
                editForm={editform.TandC}
                disabled={editform.TandC}
                checked={editform.TandC}
              />
            ) : null}

            {anchorEl == null ? (
              "Empty"
            ) : editform == null ? null : editform.TimeFlex == "true" ? (
              <TimeFlex
                group={group}
                environemntRecords={RequestformDataDB}
                environmentSelected={editform.TemplateId}
                timeFlex={editform.TimeFlex}
                setTimeFlex={editform.TimeFlex}
                disabled={editform.TimeFlex}
                checked={editform.TimeFlex}
              />
            ) : null}
            {anchorEl == null ? (
              "Empty"
            ) : editform == null ? null : editform.ScheduleHistory == "true" ? (
              <ScheduleHistoryCheckbox
                group={group}
                environemntRecords={RequestformDataDB}
                environmentSelected={editform.TemplateId}
                ScheduleHistory={editform.ScheduleHistory}
                setScheduleHistory={editform.ScheduleHistory}
                disabled={editform.ScheduleHistory}
                checked={editform.ScheduleHistory}
              />
            ) : null}

            {anchorEl == null ? (
              "Empty"
            ) : editform == null ? null : editform.RequestAnything == "true" ? (
              <RequestAnythingCheckbox
                group={group}
                environemntRecords={RequestformDataDB}
                environmentSelected={editform.TemplateId}
                RequestAnything={editform.RequestAnything}
                setRequestAnything={editform.RequestAnything}
                disabled={editform.RequestAnything}
                checked={editform.RequestAnything}
              />
            ) : null}

            {anchorEl == null ? (
              "Empty"
            ) : editform == null ? null : editform.FlexManager == "true" ? (
              <FlexManagerCheckbox
                group={group}
                environemntRecords={RequestformDataDB}
                environmentSelected={editform.TemplateId}
                editForm={editform.FlexManager}
                disabled={editform.FlexManager}
                checked={editform.FlexManager}
              />
            ) : null}

            {anchorEl == null ? (
              "Empty"
            ) : editform == null ? null : editform.LiveRecording == "true" ? (
              <LiveRecordingCheckbox
                group={group}
                environemntRecords={RequestformDataDB}
                environmentSelected={editform.TemplateId}
                liveRecording={editform.LiveRecording}
                setLiveRecording={editform.LiveRecording}
                awsliveRecording={editform.LiveRecording}
                disabled={editform.LiveRecording}
                editForm={editform.LiveRecording}
                checked={editform.LiveRecording}
              />
            ) : null}

            {anchorEl == null ? (
              "Empty"
            ) : editform == null ? null : editform.AWSLiveRecording ==
              "true" ? (
              <AWSLiveRecordingCheckbox
                group={group}
                environemntRecords={RequestformDataDB}
                environmentSelected={editform.TemplateId}
                awsliveRecording={editform.AWSLiveRecording}
                setAwsliveRecording={editform.AWSLiveRecording}
                liveRecording={editform.AWSLiveRecording}
                disabled={editform.AWSLiveRecording}
                checked={editform.AWSLiveRecording}
                editForm={editform.AWSLiveRecording}
              />
            ) : null}

            {anchorEl == null ? (
              "Empty"
            ) : editform == null ? null : editform.CallSummary == "true" ? (
              <CallSummaryCheckBox
                group={group}
                environemntRecords={RequestformDataDB}
                environmentSelected={editform.TemplateId}
                editForm={editform.CallSummary}
                disabled={editform.CallSummary}
                checked={editform.CallSummary}
              />
            ) : null}

            {anchorEl == null ? (
              "Empty"
            ) : editform == null ? null : editform.DaVinciApiCredential ==
              "true" ? (
              <DaVinciAPICredentialsCheckBox
                group={group}
                environemntRecords={RequestformDataDB}
                environmentSelected={editform.TemplateId}
                disabled={editform.DaVinciApiCredential}
                checked={editform.DaVinciApiCredential}
                editForm={editform.DaVinciApiCredential}
              />
            ) : null}

            {anchorEl == null ? (
              "Empty"
            ) : editform == null ? null : editform.IntelligentForecasting ==
              "true" ? (
              <IntelligentForecastingCheckBox
                group={group}
                environemntRecords={RequestformDataDB}
                environmentSelected={editform.TemplateId}
                editForm={editform.IntelligentForecasting}
                disabled={editform.IntelligentForecasting}
                checked={editform.IntelligentForecasting}
              />
            ) : null}

            {anchorEl == null ? (
              "Empty"
            ) : editform == null ? null : editform.ALBRequired == "true" ? (
              <ALBCheckbox
                group={group}
                environemntRecords={RequestformDataDB}
                environmentSelected={editform.TemplateId}
                disabled={editform.ALBRequired}
                checked={editform.ALBRequired}
                editForm={editform.ALBRequired}
              />
            ) : null}

            {anchorEl == null ? (
              "Empty"
            ) : editform == null ? null : editform.CloudIngestionRecorder ==
              "true" ? (
              <CloudIngestionRecorder
                group={group}
                environemntRecords={RequestformDataDB}
                environmentSelected={editform.TemplateId}
                editForm={editform.CloudIngestionRecorder}
                disabled={editform.CloudIngestionRecorder}
                checked={editform.CloudIngestionRecorder}
              />
            ) : null}

            {anchorEl == null ? (
              "Empty"
            ) : editform == null ? null : editform.EmployeeTimecollector ==
              "true" ? (
              <EmployeeTimecollector
                group={group}
                environemntRecords={RequestformDataDB}
                environmentSelected={editform.TemplateId}
                empTime={editform.EmployeeTimecollector}
                setEmpTime={editform.EmployeeTimecollector}
                disabled={editform.EmployeeTimecollector}
                checked={editform.EmployeeTimecollector}
              />
            ) : null}

            {anchorEl == null ? (
              "Empty"
            ) : editform == null ? null : editform.VTA == "true" ? (
              <VTACheckbox
                group={group}
                environemntRecords={RequestformDataDB}
                userEmail={userEmail}
                environmentSelected={editform.TemplateId}
                disabled={editform.VTA}
                checked={editform.VTA}
              />
            ) : null}

            {anchorEl == null ? (
              "Empty"
            ) : editform == null ? null : editform.OperationManager ==
              "true" ? (
              <OperationManagerCheckbox
                environmentSelected={editform.TemplateId}
                group={group}
                environemntRecords={RequestformDataDB}
                disabled={editform.OperationManager}
                checked={editform.OperationManager}
              />
            ) : null}

            {/* {(anchorEl == null )? "Empty": (editform == null )? null :  (editform.Verint_Community == "true"?


                  <CommunityCheckbox group={group} environemntRecords={RequestformDataDB} environmentSelected={editform.TemplateId} communitySelected={editform.Verint_Community } setcommunitySelected={editform.Verint_Community} disabled={editform.Verint_Community}
checked={editform.Verint_Community}/>: null)

          } */}

            {/* {(anchorEl == null )? "Empty": (editform == null )? null :  (editform.MSTeamsLiveRecording == "true"?


                  <MSTeamsAzureCheckbox environmentSelected={editform.TemplateId} editForm={editform.MSTeamsLiveRecording}   disabled={editform.MSTeamsLiveRecording}  checked={editform.MSTeamsLiveRecording}/>: null)

          } */}

            {anchorEl == null ? (
              "Empty"
            ) : editform == null ? null : editform.S3Bucket == "true" ? (
              <S3BuketCheckbox
                group={group}
                environemntRecords={RequestformDataDB}
                environmentSelected={editform.TemplateId}
                editForm={editform.S3Bucket}
                disabled={editform.S3Bucket}
                checked={editform.S3Bucket}
              />
            ) : null}

            {/* Davinci Related CheckBox */}

            {/* Davinci Related CheckBox */}

            {/* FlexManagerCheckbox Box is not coming */}

            {anchorEl == null ? (
              "Empty"
            ) : editform == null ? null : editform.Flex_Manager == "true" ? (
              <FlexManagerCheckbox
                group={group}
                environemntRecords={RequestformDataDB}
                environmentSelected={editform.TemplateId}
                disabled={editform.Flex_Manager}
                checked={editform.Flex_Manager}
              />
            ) : null}

            {anchorEl == null ? (
              "Empty"
            ) : editform == null ? null : editform.LuxUI == "true" ? (
              <LuxCheckbox
                group={group}
                environemntRecords={RequestformDataDB}
                environmentSelected={editform.TemplateId}
                Luxui={editform.LuxUI}
                setLuxui={editform.LuxUI}
                disabled={editform.LuxUI}
                checked={editform.LuxUI}
              />
            ) : null}
            {/* {(anchorEl == null )? "Empty": (editform == null )? null :  (editform.IvaPro == "true"?
        <IvaProCheckbox group={group} environemntRecords={RequestformDataDB} environmentSelected={editform.TemplateId} ivapro={editform.IvaPro} setIvapro={editform.IvaPro} disabled={editform.IvaPro} checked={editform.IvaPro} />: null)

          }   */}
          </table>

          {/* </form> */}
        </Popup>

        <VirtualTable
          //shouldDisableRow={row => row.ID === 'Ford'}
          //maxHeight={400}
          resizableColumns
          loading={isLoading}
          columnNames={columnNames}
          rows={tableRows}
          rowIdentifier="Environment"
          cellRenderers={cellRenderers}
          hasCheckboxes={false}
          hasActions={true}
          editable={false}
          customActions={customActions}
          sortable={true}
          menuTooltipText="Additional actions"
          editRowTooltipText="Edit Form"
          deleteRowTooltipText="Delete forms"
          doneEditingTooltip="Done"
          cancelEditingTooltip="Cancel"
          popupText={{
            alertText: "Alert text",
            confirmText: "Confirm",
            message: "Are you sure yoou want to save the data ?",
            rejectText: "Reject",
            title: "Popup title",
          }}
          // preSelectedRows={[
          //   {
          //     ID: 'Enviroment',
          //     model: 'Model Y',
          //     price: 64900,
          //     electric: true
          //   }
          // ]}
        />
      </div>
    </>
  );
}

export default CurrentRequestHome;
