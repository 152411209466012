
import { Popover, TextFieldV2, ComboBox } from "@verint/shared-react-components";
import React, { useState } from 'react';
import '@verint/lux_icon_font/verint_lux.css';
import OverlayText from "../../Components/OverlayText"
function LabSchedule(props) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedOption, setSelectedOption] = useState(props.LabScheduleValue);

    // Get schedule display data
    var scheduleDisplay = getScheduleDisplay(props.group);
    console.log("schedule",scheduleDisplay)

    const optionsArray = React.Children.map(scheduleDisplay.props.children, (child) => ({
        value  : child.props.value,
        key: child.props.children
  }));
      
    console.log("option array", optionsArray)
    console.log("Default Value ",optionsArray.find(o => o.key === props.LabScheduleValue))
    // Handle LabSchedule change
    const handleTimezoneChange = (selectedOption) => {
        // Handle LabSchedule change if needed
        setSelectedOption(selectedOption.value)
        console.log('Selected LabSchedule:', selectedOption.value);
        
    };

 // If component is not required, remove it from the form
 if(props.item != null && !props.item.IsAdmin  ){
    return false
 }
    return (
        <>
           
                {/* <Label
                    style={{ paddingRight: "7%" }}
                    className="verint-regular-label"
                    htmlFor="ScheduleName"
                >
                    Lab Schedule
                </Label>

                <OverlayText tooltipText={"Select the LabSchedule that is closest to your region.The LabSchedule determines what time your lab session poweron and shuts down."}></OverlayText> */}
                <tr className="editForm_table-row"   >
                    <td className="td_key">Lab Schedule</td>
                    <td className="td_value" align="left">
                            <ComboBox
                                name="ScheduleName"
                                id="ScheduleName"
                                optionKey="key" 
                                
                                options={optionsArray}
                                onChange={handleTimezoneChange}
                                defaultValue={optionsArray.find(o => o.value === props.LabScheduleValue)}
                            />
                             <TextFieldV2 style={{display:"none"}}                            
                                    id="ScheduleNameValue"
                                    name='ScheduleNameValue'
                                    data-testid="verint-textfield"
                                    defaultValue={selectedOption}
                                    value={selectedOption}
                                    className="verint-hidden-textfield"
                                    
                                />
                </td>
                </tr>
               
                
            
        </>
    );
}
    //Rewriting schedule choices
    export function getScheduleDisplay(group){
        let scheduleDisplay;
        switch(group){
            case "APAC":
                scheduleDisplay = (
                    <>
                        <option value="alwayson">24/7</option>
                        <option value="8:30AM-06:00pm-NZ">GMT+12 (NZ) (8:30 AM to 6:00 PM)(Mon-Fri)</option>
                        <option value="8:30AM-06:00pm-Sydney">GMT+10 (Sydney)(8:30 AM to 6:00 PM)(Mon-Fri)</option>
                        <option value="8:30AM-06:00pm-IST">GMT+5:30 (India)(8:30 AM to 6:00 PM)(Mon-Fri)</option>
                        <option value="nostartup-10pm-NZ">GMT+12 (NZ)</option>
                        <option value="nostartup-10pm-Sydney">GMT+10 (Sydney)</option>
                        <option value="nostartup-10pm-IST">GMT+5:30 (India)</option>
                    </>
                ); //TBD
                return scheduleDisplay;

            case "VCS":
                scheduleDisplay = (
                    <>
                        <option value="alwayson">24/7</option>
                    </>
                );
                return scheduleDisplay;

            case "APACPreSales":
                scheduleDisplay = (
                    <>
                        <option value="alwayson">24/7</option>
                        <option value="8:30AM-06:00pm-NZ">GMT+12 (NZ)(8:30 AM to 6:00 PM)(Mon-Fri)</option>
                        <option value="8:30AM-06:00pm-Sydney">GMT+10 (Sydney)(8:30 AM to 6:00 PM)(Mon-Fri)</option>
                        <option value="8:30AM-06:00pm-IST">GMT+5:30 (India)(8:30 AM to 6:00 PM)(Mon-Fri)</option>
                        <option value="8:30AM-06:00pm-Israel">GMT+3 (Israel)(8:30 AM to 6:00 PM)(Sun-Thur)</option>
                        <option value="8:30AM-06:00pm-Singapore">GMT+8 (Singapore)(8:30 AM to 6:00 PM)(Mon-Fri)</option>
                        <option value="8:30AM-06:00pm-Moscow">GMT+3 (Moscow)(8:30 AM to 6:00 PM)(Mon-Fri)</option>
                        <option value="nostartup-10pm-NZ">GMT+12 (NZ)</option>
                        <option value="nostartup-10pm-Sydney">GMT+10 (Sydney)</option>
                        <option value="nostartup-10pm-IST">GMT+5:30 (India)</option>
                        <option value="nostartup-10pm-Israel">GMT+3 (Israel)(S-T)</option>
                        <option value="nostartup-10pm-Singapore">GMT+8 (Singapore)</option>
                        <option value="nostartup-10pm-Moscow">GMT+3 (Moscow)</option>
                    </>
                );
                return scheduleDisplay;
    
            case "ISV":
                scheduleDisplay = (
                    <>
                        <option value="alwayson">24/7</option>
                        <option value="8:30AM-06:00pm-NZ">GMT+12 (NZ)(8:30 AM to 6:00 PM)(Mon-Fri)</option>
                        <option value="8:30AM-06:00pm-Sydney">GMT+10 (Sydney)(8:30 AM to 6:00 PM)(Mon-Fri)</option>
                        <option value="8:30AM-06:00pm-IST">GMT+5:30 (India)(8:30 AM to 6:00 PM)(Mon-Fri)</option>
                        <option value="8:30AM-06:00pm-Israel">GMT+3 (Israel)(8:30 AM to 6:00 PM)(Sun-Thur)</option>
                        <option value="8:30AM-06:00pm-Singapore">GMT+8 (Singapore)(8:30 AM to 6:00 PM)(Mon-Fri)</option>
                        <option value="8:30AM-06:00pm-Moscow">GMT+3 (Moscow)(8:30 AM to 6:00 PM)(Mon-Fri)</option>
                        <option value="8:30AM-06:00pm-EST">GMT-5 (EST)(8:30 AM to 6:00 PM)(Mon-Fri)</option>
                        <option value="8:30AM-06:00pm-PST">GMT-8 (Pacific)(8:30 AM to 6:00 PM)(Mon-Fri)</option>
                        <option value="8:30AM-06:00pm-GMT">GMT (UK)(8:30 AM to 6:00 PM)(Mon-Fri)</option>
                        <option value="nostartup-10pm-NZ">GMT+12 (NZ)</option>
                        <option value="nostartup-10pm-Sydney">GMT+10 (Sydney)</option>
                        <option value="nostartup-10pm-IST">GMT+5:30 (India)</option>
                        <option value="nostartup-10pm-Israel">GMT+3 (Israel)</option>
                        <option value="nostartup-10pm-Singapore">GMT+8 (Singapore)</option>
                        <option value="nostartup-10pm-Moscow">GMT+3 (Moscow)</option>
                        <option value="nostartup-10pm-EST">GMT-5 (EST)</option>
                        <option value="nostartup-10pm-PST">GMT-8 (Pacific)</option>
                        <option value="nostartup-10pm-GMT">GMT (UK)</option>
                    </>
                );
                return scheduleDisplay;        
        
            default:
                scheduleDisplay = (
                    <>
                        <option value="alwayson">24/7</option>
                        <option value="8:30AM-06:00pm-EST">GMT-5 (EST)(8:30 AM to 6:00 PM)</option>
                        <option value="8:30AM-06:00pm-PST">GMT-8 (Pacific)(8:30 AM to 6:00 PM)</option>
                        <option value="8:30AM-06:00pm-GMT">GMT (UK)(8:30 AM to 6:00 PM)</option>
                        <option value="8:30AM-06:00pm-Israel">GMT+3 (Israel)(8:30 AM to 6:00 PM)</option>
                        <option value="8:30AM-06:00pm-Singapore">GMT+8 (Singapore)(8:30 AM to 6:00 PM)</option>
                        <option value="8:30AM-06:00pm-NZ">GMT+12 (NZ)(8:30 AM to 6:00 PM)</option>
                        <option value="8:30AM-06:00pm-Sydney">GMT+10 (Sydney)(8:30 AM to 6:00 PM)</option>
                        <option value="8:30AM-06:00pm-IST">GMT+5:30 (India)(8:30 AM to 6:00 PM)</option>
                        <option value="nostartup-10pm-EST">GMT-5 (EST)</option>
                        <option value="nostartup-10pm-PST">GMT-8 (Pacific)</option>
                        <option value="nostartup-10pm-GMT">GMT (UK)</option>
                        <option value="nostartup-10pm-Israel">GMT+3 (Israel)</option>
                        <option value="nostartup-10pm-Singapore">GMT+8 (Singapore)</option>
                        <option value="nostartup-10pm-NZ">GMT+12 (NZ)</option>
                        <option value="nostartup-10pm-Sydney">GMT+10 (Sydney)</option>
                        <option value="nostartup-10pm-IST">GMT+5:30 (India)</option>
                    </>
                );
                return scheduleDisplay;
        }
    }
export default LabSchedule;