import React, {useState, useEffect} from 'react';
import IntelligentForecastingCheckBox from './IntelligentForecastingCheckBox'; 
import CallSummaryCheckBox from './CallSummaryCheckBox';
import DaVinciAPICredentialsCheckBox from './DaVinciAPICredentialsCheckBox';
import CloudIngestionRecorder from './CloudIngestionRecorder';
import ALBCheckbox from './ALBCheckbox';
import { CheckboxV2,  Label } from '@verint/shared-react-components';
import '@verint/lux_icon_font/verint_lux.css';
import OverlayText from "./OverlayText";

/* Set the value of DaVinci checkbox */
function DaVinciCheckBox(props) {
    
    const [envRecords, SetEnvRecords] = useState([]);
    const [envSelected, SetEnvSelected] = useState("");
    const [checked, setChecked] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [isDisabled, setIsDisabled] = useState(false);
    const open = Boolean(anchorEl);
    let is_davinci = get_daVinci_templateId(props);
    
    // If selected environment is cannot use the checkbox, reset value to default
    useEffect(() => {
        SetEnvRecords(props.environemntRecords)
        SetEnvSelected(props.environmentSelected)
        if(should_display_DaVinci_checkbox(props) === false){
            setChecked(false)
        }
        if(is_davinci === true){
          
          setIsDisabled(true)
          setChecked(true)
        }
        else if(is_davinci === false){
          setIsDisabled(false)
          setChecked(false)
        }        
    }, [props.environmentSelected])
    console.log("Davinci envSelected: ", SetEnvRecords)

    // If selected environment is cannot use the checkbox, hide
    if(should_display_DaVinci_checkbox(props) === false){
        return null
    }
    
    return <>
     <div className="row" id= "DaVinciRow" style={{display:'none'}}>

            <CheckboxV2 className="verint-CheckboxV2-regular" disabled={isDisabled} id="DaVinci" 
              name="DaVinci" type = "checkbox" value = {checked} checked={checked} onChange={(e)=>{setChecked(!checked)}} ></CheckboxV2>    

        <Label id="DaVinci_label" className="verint-regular-label" style={{padding:"6px",paddingRight:"38px"}} forhtml="DaVinci">Da Vinci </Label>  
        <OverlayText style={{paddingTop:"5px"}} tooltipText={"Select this checkbox if you require any of the following: Identity, RTAA, Work Assist, AQM (AI) and Da Vincipost call transcription. More features and services will be added soon."}></OverlayText>
      
        </div>  
        <CallSummaryCheckBox group={props.group} environemntRecords={props.environemntData} environmentSelected={props.environmentSelected} daVinciChecked={checked} setDaVinciChecked={setChecked}></CallSummaryCheckBox>  
        <DaVinciAPICredentialsCheckBox group={props.group} environemntRecords={props.environemntData} environmentSelected={props.environmentSelected} daVinciChecked={checked} setDaVinciChecked={setChecked} empTime={props.empTime}></DaVinciAPICredentialsCheckBox> 
        <IntelligentForecastingCheckBox group={props.group} environemntRecords={props.environemntData} environmentSelected={props.environmentSelected} daVinciChecked={checked} setDaVinciChecked={setChecked}></IntelligentForecastingCheckBox>  
        <ALBCheckbox group={props.group} environemntRecords={props.environemntRecords} environmentSelected={props.environmentSelected} daVinciChecked={checked} setDaVinciChecked={setChecked}></ALBCheckbox>  
        <CloudIngestionRecorder group={props.group} environemntRecords={props.environemntRecords} environmentSelected={props.environmentSelected} daVinciChecked={checked} setDaVinciChecked={setChecked}></CloudIngestionRecorder>  
    </>      
}

export default DaVinciCheckBox;
function should_display_DaVinci_checkbox(props)
{
    var jsonData = props.environemntRecords
    for( var i = 0; i < jsonData.Count; i++)
    {
        if(jsonData.Items[i].group_name.includes(props.group) && jsonData.Items[i].template_id === props.environmentSelected )
        {
            if(jsonData.Items[i].DaVinci_checkbox  === true){
                return true
            }
            else 
            {
                return false
            }
        }
    }
    return false
}

//Validate if templateId has preselected DaVinci 
function get_daVinci_templateId(props)
{
    var jsonData =props.environemntRecords
    for( var i =0; i < jsonData.Count; i++)
    {        
        if(jsonData.Items[i].template_id === "0" )
        {                  
         
            // Catch error that can be caused by a null user_email 
            try{
                var daVinci_templateId = props.environmentSelected;
            }
            catch{}
            if(jsonData.Items[i].davinci_labs_templateIds.includes(daVinci_templateId)){
                return true
            }
            else 
            {
                return false
            }
        }
    }
}

