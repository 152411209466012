import React, { useState, useEffect } from "react";
import { CheckboxV2, Label } from "@verint/shared-react-components";
import "@verint/lux_icon_font/verint_lux.css";
import OverlayText from "./OverlayText";

/* Set the value of Cloud Ingestion Recorder checkbox */
function CloudIngestionRecorder(props) {
    
    //console.log("Json data Cloud Ingetsion before: ", props)
    const [checked, setChecked] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    
    useEffect(() => {
        if(should_display_cloudIngestionRecorder_checkbox(props) === false){
            setChecked(false)
        }        
    }, [props.environmentSelected])

  //If selected environment cannot use the checkbox, hide
  if (should_display_cloudIngestionRecorder_checkbox(props) === false) {
    return null;
  }

  // If DaVinci Checkbox is set to true, only then display Cloud Ingestion Recorder Checkbox
  return (
    <>
      {/*  Cloud Ingestion Recorder Checkbox  */}
      <tr className="reqForm_tablerow">
        <td className="td_row_leftvalue">
          <CheckboxV2
            className="verint-CheckboxV2-regular"
            id="CloudIngestionRecorder"
            style={{ marginTop: "3px" }}
            name="CloudIngestionRecorder"
            type="checkbox"
            value={checked}
            onChange={(e) => {
              setChecked(!checked);
            }}
            checked={props.checked}
            disabled={props.disabled}
          ></CheckboxV2>

          <Label
            id="cloudIngestionRecorder_label"
            className="verint-regular-label"
            forhtml="CloudIngestionRecorder"
          >
            Cloud Ingestion Recorder{" "}
          </Label>
          <OverlayText
            tooltipText={
              "Select this option to include Cloud Ingestion Recorder"
            }
          ></OverlayText>
        </td>
      </tr>
    </>
  );
}

export default CloudIngestionRecorder;

function should_display_cloudIngestionRecorder_checkbox(props)
{
    //console.log("Json data Cloud Ingetsion: ", props)
    var jsonData = props.environemntRecords
    for( var i =0; i < jsonData.Count; i++)
    {
        if(jsonData.Items[i].group_name.includes(props.group) && jsonData.Items[i].template_id === props.environmentSelected)
        {
            if(jsonData.Items[i].CloudIngestionRecorder_Checkbox  === true){
                return true
            }
            //checking the editform property from current request
            if (props.editForm === "true") {
              return true;
            }
            else 
            {
                return false
            }
        }
    }
    return false
}
