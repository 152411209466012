// Provides input for the number of desktops which can be extended later if required

import React, { useEffect, useState } from "react";
import { NumericStepper } from "@verint/shared-react-components";
import { Label, CheckboxV2 } from "@verint/shared-react-components";
import "@verint/lux_icon_font/verint_lux.css";

// Component created with arrow function making use of hooks
function DesktopNumber(props) {
  const [desktopNumber, setDesktopNumber] = useState(props.numberOfDesktops);
  const min = 0;
  const max = 20;

  useEffect(() => {
    if (props.editForm > 0) {
      const parsedValue = parseInt(props.editForm);
      setDesktopNumber(parsedValue);
      console.log("Desktops Number", parsedValue);
    }
  }, [props.editForm]);
  console.log("Desktops type Nuber", typeof props.editForm);
  console.log("Desktops outside Nuber", props.editForm);
  const onChange = (e) => {
    const value = parseInt(e.target.value, 10);
    if (value >= min && value <= max) {
      setDesktopNumber(value);
    }
  };

  // If component is not required, remove it from the form
  if (props.item != null && props.item.Status === "Deployed") {
    return false;
  }
  return (
    <>
      {/* <td className="td_key">Desktops</td> */}

      {props.disabled && (
        <tr className="editForm_table-row">
          <td className=" td_row_leftvalue">
            <CheckboxV2
              className="verint-CheckboxV2-regular"
              id="Desktops"
              style={{ marginTop: "3px" }}
              name="Desktops"
              type="checkbox"
              checked={props.checked}
              disabled={props.disabled}
            ></CheckboxV2>
            <Label
              className="verint-regular-label"
              forhtml="AdditionalVcorpUsers"
            >
              Desktops{" "}
            </Label>
          </td>
          <td className="td_key">
            <NumericStepper
              id="desktopNumverValue"
              data-testid="verint-numeric-desktopNumber"
              defaultValue={desktopNumber}
              onChange={onChange}
              value={desktopNumber}
              min={min}
              max={max}
              disabled={props.editForm}
            />
          </td>
        </tr>
      )}
      {!props.disabled && (
        <tr className="editForm_table-row">
          {/* <td className=" td_row_leftvalue">
            <Label
              className="verint-regular-label"
              forhtml="AdditionalVcorpUsers"
            >
              Desktops{" "}
            </Label>
          </td> */}
          <td className="td_key">Desktops</td>

          {/* <Label className="verint-regular-label" forhtml="AdditionalVcorpUsers">Desktops </Label>  */}
          <td className="td_value" align="left">
            <NumericStepper
              id="desktopNumverValue"
              name="NumberOfDesktops"
              data-testid="verint-numeric-desktopNumber"
              defaultValue={desktopNumber}
              onChange={onChange}
              value={desktopNumber}
              min={min}
              max={max}
              disabled={props.editForm}
            />
          </td>
        </tr>
      )}
    </>
  );
}
export default DesktopNumber;
